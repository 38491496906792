<template>
  <div>
    <b-card>
      <page-title v-if="titleStatus" :title="$t('aboutUs.items')" />

      <main-table
        :fields="fields"
        :list_url="'admin/about-us'"
        :reloadData="reloadData"
        :params="filter"
        :createStatus="false"
        :excelFields="excelFields"
        excelName="aboutUs"
      />
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import AboutUsFilter from "../models/AboutUsFilter";
import searchMixin from "../../../../Mixins/searchMixin";

export default {
  mixins: [searchMixin],
  data() {
    return {
      filter: new AboutUsFilter(),
      fields: [
        this.$t("global.selected"),
        { key: "id", label: this.$t("table.id"), sortable: true },
        { key: "heading", label: this.$t("global.heading"), sortable: true },
        { key: "content", label: this.$t("global.content"), sortable: true },
        {
          key: "actions",
          label: this.$t("table.actions"),
          type: "actions",
          actions: [
            {
              text: this.$t("global.view"),
              icon: "fas fa-eye",
              color: "info",
              ifNavigate: true,
              routeName: "generalSettings.aboutUsView",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.update"),
              icon: "fas fa-pen",
              color: "primary",
              ifNavigate: true,
              routeName: "generalSettings.aboutUsUpdate",
              routeParams: ["id"],
            },
            // {
            //   text: this.$t('global.delete'),
            //   icon: 'fas fa-trash-alt',
            //   color: 'danger',
            //   showAlert: true,
            //   actionHeader: this.$t('global.delete'),
            //   titleHeader: this.$t('aboutUs.item'),
            //   textContent: 'address',
            //   url: 'admin/about-us',
            //   type: 'delete'
            // }
          ],
        },
      ],
      excelFields: {
        [this.$t("table.id")]: "id",
        [this.$t("global.heading")]: "heading",
        [this.$t("global.content")]: "content",
        [this.$t("table.createdAt")]: "created_at",
      },
    };
  },
  props: {
    cityId: { default: "" },
  },
  mounted() {
    core.index();
  },
  created() {
    this.filter.city_id = this.cityId;
  },
};
</script>
